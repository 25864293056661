
import moment,{ Moment } from 'moment'
import { Options, Vue } from 'vue-class-component';
import { Emit, Provide, Watch } from 'vue-property-decorator';
import { list, check, upload, exportfile } from '@/api/order';
import { theManagementList } from '@/api/platform';
import { message } from 'ant-design-vue';
import { deepCopy } from '@/untils/until'
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { Modal, notification } from 'ant-design-vue';
import { defineComponent, h } from 'vue';
type Key = ColumnProps['key'];
interface IDataType {
  id: number,
  ddbh: string;
  fbfnsrmc: string;
  state: number;
  qsfwsj: string;
  hwmc: string;
  hwzl: string;
  fwfxm: string;
  cycph: string;
  jsfwsj: string;
  jssj: string;
}
interface ISearchForm {
  pageNum: number,
  pageSize: number,
  ddbh: string,
  fbfnsrmc: string,
  cycph: string,
  qsfwsj: string,
  fwfxm: string,
  state: number,
  jssjq: string,
  jssjz: string,
  ids: any,
  payDate: any,
}
@Options({
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
})
export default class PushOrder extends Vue {
  @Provide() total: number = 0
  @Provide() totalFbfddje: number = 0
  @Provide() totalFwfddje: number = 0
  @Provide() stateData : Array<string> = ['全部','待处理','已验证','已上传', '上传中']
  @Provide() columns: any = [
    {
      title: '序号',
      customRender: ({text, record, index} :any)=> {
        return this.searchForm.pageNum > 1 ? `${(this.searchForm.pageNum -1)*(this.searchForm.pageSize)+index+1}`:index+1
      },
      width: 80
    },
    {
      title: '订单编号',
      className: 'column-money',
      dataIndex: 'ddbh',
      width: 155,
      slots: { customRender: 'number' },
    },
    {
      title: '发布方企业名称',
      dataIndex: 'fbfnsrmc',
      width: 120,
      ellipsis: true,
    },
    {
      title: '验证状态',
      dataIndex: 'state',
      width: 120,
      slots: { customRender: 'states' },
    },
    {
      title: '起始运输时间',
      dataIndex: 'qsfwsj',
      width: 100,
      ellipsis: true,
    },
    {
      title: '货物名称',
      dataIndex: 'hwmc',
      width: 100,
      ellipsis: true,
    },
    {
      title: '货物重量（吨）',
      dataIndex: 'hwzl',
      width: 120,
    },
    {
      title: '（车主）姓名',
      dataIndex: 'fwfxm',
      width: 120,
    },
    {
      title: '承运车牌号',
      dataIndex: 'cycph',
      width: 120,
    },
    {
      title: '结束运输时间',
      dataIndex: 'jsfwsj',
      width: 120,
      ellipsis: true,
    },
    {
      title: '提现付款时间',
      dataIndex: 'jssj',
      width: 120,
      ellipsis: true,
    },
  ];
  @Provide() data: any =[];
  @Provide() publishName: any =[];
  @Provide() searchForm: ISearchForm = {
    pageNum: 1,
    pageSize: 10,
    ddbh: '',
    fbfnsrmc: '',
    cycph: '',
    qsfwsj: '',
    fwfxm: '',
    state: 1,
    jssjq: '',
    jssjz: '',
    ids: [],
    payDate: [],
  }
  @Provide() selectedRowKeys:Key[] = []
  getState(val: number) {
    let state = ''
    switch(val) {
      case 1:
        state = '待验证'
        break;
      case 2:
        state = '验证完成'
        break;
      case 3:
        state = '已推送'
        break;
      case 4:
        state = '推送失败'
        break;
      case 5:
        state = '验证失败'
        break;
    }
    return state
  }
  mounted() {
    if(sessionStorage.getItem('isDetail')){
      let aa: any=sessionStorage.getItem('pra')
      let bb: any=JSON.parse(aa)
      this.searchForm=Object.assign({},this.searchForm,bb)
    }
    this.list()
    this.getPublishName()
  }
  getPublishName(){
    theManagementList().then((res: any) =>{
      this.publishName = res.data.list
    })
  }
  selectPublishName(val: any){
    // let aa=[]
    // aa.push(`${val.join()}`)
    this.searchForm = Object.assign({},this.searchForm,{ids:val})
  }
  addItem() {
    let val=this.publishName.map((item:any) => item.id)
    this.searchForm.ids = val
  }
  clearItem() {
    this.searchForm.ids = []
  }
  detailLink(record: any) {
    sessionStorage.setItem('pra',JSON.stringify(this.searchForm))
    return `/order/${record.id}`
  }
  onChange(pageNumber: number) :void {
    this.searchForm.pageNum = pageNumber
    this.list('pagination')
  }
  failReason(text: string):void {
    Modal.warning({
      content: text,
      okText: '确定',
      class: 'aConfirm',
      centered: true,
    });
  }
  onSelectChange (selectedRowKeys: Key[]) {
    this.selectedRowKeys = selectedRowKeys
  }
  getCheckboxProps(record: any) { 
    return ({ disabled: record.checkState == 0 })
  }
  onSearch () {
    this.searchForm.pageNum = 1
    this.list('search')
  }
  onchangeDate(date: string, dateString: string): void {
    this.searchForm.jssjq = dateString[0]
    this.searchForm.jssjz = dateString[1]
  }
  list(type?: string) :void {
    const newSearch = deepCopy(this.searchForm)
    newSearch.qsfwsj = this.searchForm.qsfwsj ? moment(this.searchForm.qsfwsj).format('YYYY-MM-DD'): ''
    newSearch.jssjq = this.searchForm.payDate.length>0 ? moment(this.searchForm.payDate[0]).format('YYYY-MM-DD'): ''
    newSearch.jssjz = this.searchForm.payDate.length>0 ? moment(this.searchForm.payDate[1]).format('YYYY-MM-DD'): ''
    const params:any = this.$router.currentRoute.value.query.query
    let data = newSearch
    if (params){ data = {ddids:params} }
    if (type && params) {
      data = newSearch
    }
    delete data.payDate
    list(data).then((res: any)=> {
      if (res.code == 200) {
        this.total = res.data.total
        this.totalFbfddje= res.data.totalFbfddje
        this.totalFwfddje= res.data.totalFwfddje
        this.data = res.data.list
        this.selectedRowKeys = []
      }
    })
  }
  onValide(): void {
    if (!this.selectedRowKeys.length) {
      Modal.warning({
        content: h('p', '请先选中数据再验证！'),
        okText: '确定',
        class: 'aConfirm',
        centered: true,
      })
    }
    let num: any = []
    for (let i in this.selectedRowKeys) {
      num.push(this.selectedRowKeys[i])
    }
    check(num).then((res: any)=> {
      if (res.code == 200) {
        message.info(res.message, 5)
        this.list()
      }
    })
  }
  onUpload(): void {
    if (!this.selectedRowKeys.length) {
      Modal.warning({
        content: h('p', '请先选中数据再推送！'),
        okText: '确定',
        class: 'aConfirm',
        centered: true,
      })
    }
    let num: any = []
    for (let i in this.selectedRowKeys) {
      num.push(this.selectedRowKeys[i])
    }
    upload(num).then((res: any)=> {
      if (res.code == 200) {
        message.success(res.message, 10)
        this.list()
      }
    })
  }
  //导出功能
  allExport() {
    const _ = this;
    let paramsExport={
      ddbh: this.searchForm.ddbh,
      cycph: this.searchForm.cycph,
      qsfwsj: this.searchForm.qsfwsj ? moment(this.searchForm.qsfwsj).format('YYYY-MM-DD'): '',
      fwfxm: this.searchForm.fwfxm,
      state: this.searchForm.state,
      jssjq: this.searchForm.payDate.length>0 ? moment(this.searchForm.payDate[0]).format('YYYY-MM-DD'): '',
      jssjz: this.searchForm.payDate.length>0 ? moment(this.searchForm.payDate[1]).format('YYYY-MM-DD'): '',
      ids: this.searchForm.ids||[],
    }
    Modal.confirm({
      title: "确认导出？",
      okText: "是",
      cancelText: "否",
      onOk() {
        return new Promise<void>( resolve => {
          exportfile(paramsExport).then((res: any) => {
            resolve();
            let blob = new Blob([res], {
              type: 'application/vnd.ms-excel'
            })
            let objectUrl = URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = objectUrl
            a.download = '导出日志列表.xlsx' // 文件名 ，
            // a.click();
            // 下面这个写法兼容火狐
            a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}))
            window.URL.revokeObjectURL('blob')
            notification.success({
              message: "导出成功",
              description: res.msg
            })
          })
        })
      },
    })
  }
}
